/* global Flatpickr */

const dateRanges = {
    bound: [],
    bind: (inputs) => {
        Array.from(inputs).forEach((dateInput) => {
            const flatPickrObj = new Flatpickr(dateInput, {
                mode: 'range',
                onClose: (s, d, i) => dateRanges.mountDateClear(s, d, i),
            });
            dateRanges.bound.push(flatPickrObj);
        });
    },
    unbind: () => {
        if (dateRanges.bound.length === 0) return;

        dateRanges.bound.forEach((instance, index) => {
            const clearBtn = document.querySelector('.js-date-range-clear');
            if (clearBtn) {
                clearBtn.parentElement.removeChild(clearBtn);
            }
            instance.clear();
            instance.destroy();
            dateRanges.bound.splice(index, 1);
        });
    },
    unmountDateClear: (instance, inputWrapper, clearBtn) => {
        instance.clear();
        clearBtn.removeEventListener('click', dateRanges.unmountDateClear);
        inputWrapper.removeChild(clearBtn);
    },

    mountDateClear: (selectedDates, dateString, instance) => {
        const inputWrapper = instance.input.offsetParent;
        let clearBtn = inputWrapper.querySelector('.js-date-range-clear');

        if (selectedDates.length === 1 && clearBtn) {
            dateRanges.unmountDateClear(instance, inputWrapper, clearBtn);
        }

        if (selectedDates.length !== 2) return;

        clearBtn = document.createElement('span');
        clearBtn.className = 'js-date-range-clear date-range-clear';
        clearBtn.innerText = '- Clear';

        inputWrapper.insertBefore(clearBtn, inputWrapper.firstChild);

        clearBtn.addEventListener('click', dateRanges.unmountDateClear.bind(null, instance, inputWrapper, clearBtn));
    },
    setup: (inputs) => {
        dateRanges.unbind();
        dateRanges.bind(inputs);
    },
};

export const cleanFilters = (query) => {
    const q = query;
    if ((q.value && q.value === 'all') || q.filter === 'all') {
        delete q.value;
        delete q.filter;
    }
    return q;
};

export const showSearchFilter = () => {
    const tableFiltersEl = document.querySelector('.js-table-controls__filter');
    tableFiltersEl.classList.remove('download');
    tableFiltersEl.classList.add('filter');

    const dateInputs = tableFiltersEl.querySelectorAll('.js-date-range');
    dateRanges.setup(dateInputs);
};

export const showDownloadFilter = () => {
    const tableFiltersEl = document.querySelector('.js-table-controls__filter');
    tableFiltersEl.classList.remove('filter');
    tableFiltersEl.classList.add('download');

    const dateInputs = tableFiltersEl.querySelectorAll('.js-date-range');
    dateRanges.setup(dateInputs);
};

export const hideFilters = () => {
    const tableFiltersEl = document.querySelector('.js-table-controls__filter');
    tableFiltersEl.classList.remove('download');
    tableFiltersEl.classList.remove('filter');

    dateRanges.unbind();
};

export const trimSpaces = (input) => {
    if (typeof input === 'string') {
        return input.trim();
    } if (input && Object.keys(input).length > 0) {
        Object.entries(input)
            .forEach(([key, value]) => {
                input[key] = trimSpaces(value); // eslint-disable-line
            });
    }
    return input;
};
