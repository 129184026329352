import { jwtDecode } from 'jwt-decode';
import {
    isLoggedIn,
    isAdmin,
    ensureLoggedIn,
    ensureAdminLoggedIn,
    ensureIntermediateAdminLoggedIn,
    ensureSuperAdminLoggedIn,
    ensureLoggedInAndProductRequestsAreSupported,
} from '../utils/api/auth';

// import admin from './admin';
import app from './app';
import user from './user';
import auth from './auth';

const routes = [].concat(
    auth,
    user,
    app,
    // admin,
    { path: '/contact', name: 'contact', component: resolve => require(['./contact/component.vue'], resolve) }, // eslint-disable-line
    { path: '*', component: resolve => require(['./../components/404.vue'], resolve) }, // eslint-disable-line
);

const PUBLIC_ROUTES = [
    '/auth/login',
    '/auth/callback',

    '/auth/verify',
    '/auth/email/change',
    '/auth/merge',
    '/auth/app/enable',

    '/auth/register',
    '/auth/register/invite',
    '/auth/forgot',
    '/admin/login',
];

export default routes.map((route) => {
    const finalRoute = route;

    // require auth on all but login/register
    if (!PUBLIC_ROUTES.includes(route.path)) {
        finalRoute.beforeEnter = ensureLoggedIn;
    }

    if (route.path.includes('product-requests')) {
        finalRoute.beforeEnter = ensureLoggedInAndProductRequestsAreSupported;
    }

    // require user to be admin for /admin
    if (/^\/admin\/?.*/.test(route.path) && !PUBLIC_ROUTES.includes(route.path)) {
        if (finalRoute.meta) {
            if (finalRoute.meta.requiresIntermediateAdmin) {
                finalRoute.beforeEnter = ensureIntermediateAdminLoggedIn;
            } else if (finalRoute.meta.requiresSuperAdmin) {
                finalRoute.beforeEnter = ensureSuperAdminLoggedIn;
            } else {
                finalRoute.beforeEnter = ensureAdminLoggedIn;
            }
        } else {
            finalRoute.beforeEnter = ensureAdminLoggedIn;
        }
    }

    // shared auth
    if (/^\/auth\/password\/?/.test(route.path)) {
        finalRoute.beforeEnter = (to, from, next) => {
            const loggedIn = isLoggedIn() || isAdmin();
            if (to.name === 'password-reset') {
                if (loggedIn) {
                    next({ path: '/' });
                } else if (to.query.auth) {
                    try {
                        const usr = jwtDecode(to.query.auth);
                        if (usr) return next(); // allow through if token can be decoded
                    } catch (ex) { /* ignore */ }
                }
            } else if (loggedIn) {
                return next();
            }
            return next({ name: 'login', query: { next: to.path, ...to.query } });
        };
    }

    return finalRoute;
});
