<template>
<div id="at-banner" v-if="isStaging || isAliasing">
    <p v-if="isStaging">Staging Environment</p>
    <div class="aliasing" v-if="isAliasing">
        <p>{{ admin.firstName }} logged in as {{ user | fullName }}. <a class="banner-aliasing-exit" href="" v-on:click.prevent="endAliasing">Switch Back</a></p>
    </div>
</div>
</template>
<style>
#at-banner {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: .5rem .9375rem;
    background-color: #e73e3b;
    z-index: 9999;
}

#at-banner .aliasing {
    margin-top: .1em;
}

#at-banner p {
    text-align: center;
    margin-bottom: 0;
    color: #fff;
}

#at-banner p .banner-aliasing-exit:link,
#at-banner p .banner-aliasing-exit:visited,
#at-banner p .banner-aliasing-exit:hover,
#at-banner p .banner-aliasing-exit:active {
    color: #fff !important;
    border-bottom: 1px solid #fff !important;
}
</style>
<script>
/* global Cookies */
import admin from '../utils/api/admin';
import { isAliasing, getUser, getAliasingUser } from '../utils/api/auth';
import endAliasing from '../utils/ui/endAliasSession';

export default {
    data: () => ({
        isStaging: process.env.STAGING,
        isAliasing: isAliasing(),
        user: getUser(),
        admin: getAliasingUser(),
    }),
    filters: {
        fullName: (user) => {
            if (user.firstName) {
                return `${user.firstName} ${user.lastName || ''}`;
            }
            return user.username || 'Unnamed User';
        },
    },
    methods: {
        endAliasing: async function fx() {
            await endAliasing(this.user.email);
        },
    },
};
</script>
