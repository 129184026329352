/* global nest, Cookies, Lockr */

import { getDomain } from '..';
import admin from '../api/admin';
import { COOKIES } from '../api/invoke';

const endAliasing = async (email) => {
    Cookies.remove(COOKIES.ADMIN_ALIAS, { domain: getDomain() });
    const cookie = Cookies.get(COOKIES.ADMIN_ALIAS, { domain: getDomain() });
    Lockr.rm('admin.alias.defaultApp');
    Lockr.rm('admin.alias.showNotification');
    if (!cookie) {
        await admin.endAlias(email);
        window.location = nest.admin.baseUrl;
    }
};

export default endAliasing;
