const Payment = resolve => require(['./payment/component.vue'], resolve); // eslint-disable-line
const Statement = resolve => require(['./statement/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/billing/payment/methods', name: 'billing-payment', component: Payment, meta: { subtitle: 'Payment Methods' },
    },
    {
        path: '/billing/statement/:type', name: 'billing-statement', component: Statement, meta: { subtitle: 'Account Statement' },
    },
];
