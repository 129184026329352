const Analytics = resolve => require(['./analytics/component.vue'], resolve); // eslint-disable-line
const Transactions = resolve => require(['./transactions/component.vue'], resolve); // eslint-disable-line
const RequestPayment = resolve => require(['./request/component.vue'], resolve); // eslint-disable-line
const Products = resolve => require(['./products/list/component.vue'], resolve); // eslint-disable-line
const ProductTransactions = resolve => require(['./products/transactions.vue'], resolve); // eslint-disable-line
const CreateProduct = resolve => require(['./sandbox/create/product/component.vue'], resolve); // eslint-disable-line
const CreateChannel = resolve => require(['./sandbox/create/channel/component.vue'], resolve); // eslint-disable-line
const TopupWallet = resolve => require(['./sandbox/topup/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/mobile-data/products', name: 'mobile-data-products', component: Products, meta: { subtitle: 'Products' },
    },
    { path: '/mobile-data/request', name: 'mobile-data-request', component: RequestPayment },
    {
        path: '/mobile-data/transactions', name: 'mobile-data-transactions', component: Transactions, meta: { subtitle: 'Transactions' },
    },
    {
        path: '/mobile-data/transactions/product/:product', name: 'mobile-data-transactions-product', component: ProductTransactions, meta: { subtitle: 'Transactions' },
    },
    {
        path: '/mobile-data/analytics', name: 'mobile-data-analytics', component: Analytics, meta: { subtitle: 'Analytics' },
    },
    {
        path: '/mobile-data/analytics/product/:product', name: 'mobile-data-analytics-product', component: Analytics, meta: { subtitle: 'Product Analytics' },
    },

    // sandbox
    { path: '/mobile-data/create/product', name: 'sandbox-mobile-data-create-product', component: CreateProduct },
    { path: '/mobile-data/create/product/:product/channel', name: 'sandbox-mobile-data-create-channel', component: CreateChannel },
    { path: '/mobile-data/topup', name: 'sandbox-mobile-data-topup', component: TopupWallet },
];
