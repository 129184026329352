const UserAccount = resolve => require(['./component.vue'], resolve); // eslint-disable-line

export default [
    {
        name: 'account',
        path: '/user/account',
        component: UserAccount,
        meta: { title: 'Account', subtitle: 'Manage your account' },
    },
];
