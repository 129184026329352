/* global nest */
/* eslint-disable */
module.exports = () => {
    window.umami = {
        track: () => {}
    };
    const script = document.createElement('script');
    script.src = nest.analytics.umami.script;
    script.defer = true;
    script.async = true;
    script.dataset.cache = true;
    script.dataset.doNotTrack = true;
    script.dataset.websiteId = nest.analytics.umami.id;
    document.body.appendChild(script);
}