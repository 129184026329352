<template>
  <footer>
    <div class="medium-12 columns footer clearfix">
      <span class="footer__copy">&copy; Africa's Talking, All rights reserved.</span>
    </div>
  </footer>
</template>
<script>
export default {
    data: () => ({}),
};
</script>
