/* global nest, Cookies, Lockr */
import { jwtDecode } from 'jwt-decode';
import invoke, { COOKIES } from './invoke';

import { trimSpaces } from '../filters';

// FIXME: Break me up!!!!!!!

export default {

    startAlias: async (email) => {
        try {
            const token = await invoke('GET', `/admin/auth/alias/${email}`);
            const user = jwtDecode(token);
            Cookies.set(COOKIES.ADMIN_ALIAS, token, { expires: COOKIES.getExpirationTime() });
            Lockr.set('admin.alias.showNotification', true);
            return user;
        } catch (ex) {
            return null;
        }
    },
    endAlias: async (email) => {
        try {
            await invoke('POST', `/admin/auth/alias/${email}/revoke`, null, { noAlias: true });
            const user = jwtDecode(Cookies.get(COOKIES.TOKEN));
            return user;
        } catch (ex) {
            return null;
        }
    },

    tasks: {
        tests: {
            sendSMS: async (params) => {
                try {
                    return await invoke('POST', '/admin/tasks/tests/sms', trimSpaces(params));
                } catch (ex) {
                    return false;
                }
            },

            createPremiumSubscription: async (params) => {
                try {
                    return await invoke('POST', '/admin/tasks/tests/premium/subscription/create', trimSpaces(params));
                } catch (ex) {
                    return false;
                }
            },

            deletePremiumSubscription: async (params) => {
                try {
                    return await invoke('POST', '/admin/tasks/tests/premium/subscription/delete', trimSpaces(params));
                } catch (ex) {
                    return false;
                }
            },
        },

        topup: {
            addMpesa: async (params) => {
                try {
                    await invoke('POST', '/admin/tasks/topup/mpesa', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            addCheque: async (params) => {
                try {
                    await invoke('POST', '/admin/tasks/topup/cheque', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            addRefund: async (params) => {
                try {
                    await invoke('POST', '/admin/tasks/topup/refund', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            topupWallet: async (params, isRefund = false) => {
                try {
                    await invoke('POST', `/admin/tasks/topup/wallet${isRefund ? '/refund' : ''}`, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            replayWalletTransaction: async (params) => {
                try {
                    await invoke('POST', '/admin/tasks/topup/wallet/replay', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },
        expenditures: {
            addEmail: async (params) => {
                try {
                    await invoke('POST', '/admin/emails/expenditure/create', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },
            listEmails: async (query = { page: 0, count: 10 }) => {
                try {
                    let url = '/admin/emails/expenditure';
                    const params = Object.keys(trimSpaces(query)).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    const emails = await invoke('GET', url);
                    return emails || [];
                } catch (ex) {
                    return false;
                }
            },
            deleteEmail: async (memberId) => {
                try {
                    await invoke('DELETE', `/admin/emails/expenditure/${memberId}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },

        balance: {
            addEmail: async (params) => {
                try {
                    await invoke('POST', '/admin/emails/balance/create', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },
            listEmails: async (query = { page: 0, count: 10 }) => {
                try {
                    let url = '/admin/emails/balance';
                    const params = Object.keys(trimSpaces(query)).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    const emails = await invoke('GET', url);
                    return emails || [];
                } catch (ex) {
                    return false;
                }
            },
            deleteEmail: async (memberId) => {
                try {
                    await invoke('DELETE', `/admin/emails/balance/${memberId}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },

        config: {

            sms: {
                createSmsGatewayOverride: async (params) => {
                    try {
                        await invoke('POST', '/admin/tasks/sms/gateway/overrides', trimSpaces(params));
                        return true;
                    } catch (ex) {
                        return false;
                    }
                },
                fetchSmsGatewayOverrides: async (query = { page: 0, count: 10 }) => {
                    try {
                        let url = '/admin/tasks/sms/gateway/overrides';
                        query = trimSpaces(query); // eslint-disable-line
                        const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                        url = `${url}${params.replace(/&$/, '')}`;
                        const overrides = await invoke('GET', url);
                        return overrides || [];
                    } catch (ex) {
                        return false;
                    }
                },

                deleteSmsGatewayOverride: async (overrideId) => {
                    try {
                        await invoke('DELETE', `/admin/tasks/sms/gateway/overrides/${overrideId}`);
                        return true;
                    } catch (ex) {
                        return false;
                    }
                },

                getSmsGatewayConfigs: async () => {
                    try {
                        return await invoke('GET', '/admin/tasks/sms/gateway/configs');
                    } catch (ex) {
                        return false;
                    }
                },
            },
            airtime: {
                createAirtimeGatewayOverride: async (params) => {
                    try {
                        await invoke('POST', '/admin/tasks/airtime/gateway/overrides', trimSpaces(params));
                        return true;
                    } catch (ex) {
                        return false;
                    }
                },
                fetchAirtimeGatewayOverrides: async (query = { page: 0, count: 10 }) => {
                    try {
                        let url = '/admin/tasks/airtime/gateway/overrides';
                        query = trimSpaces(query); // eslint-disable-line
                        const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                        url = `${url}${params.replace(/&$/, '')}`;
                        const overrides = await invoke('GET', url);
                        return overrides || [];
                    } catch (ex) {
                        return false;
                    }
                },

                deleteAirtimeGatewayOverride: async (overrideId) => {
                    try {
                        await invoke('DELETE', `/admin/tasks/airtime/gateway/overrides/${overrideId}`);
                        return true;
                    } catch (ex) {
                        return false;
                    }
                },

                getAirtimeGatewayConfigs: async () => {
                    try {
                        const configs = await invoke('GET', '/admin/tasks/airtime/gateway/configs');
                        return configs;
                    } catch (ex) {
                        return false;
                    }
                },
            },
        },
    },

    apps: {
        fetchApps: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/apps';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                const apps = await invoke('GET', url);
                return apps || [];
            } catch (ex) {
                return [];
            }
        },

        updateApp: async (appId, data) => {
            try {
                return await invoke('POST', `/admin/apps/app/${appId}`, trimSpaces(data));
            } catch (ex) {
                return null;
            }
        },

        updateRegistry: async (data) => {
            try {
                await invoke('POST', '/admin/apps/registry', trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchFromRegistry: async (query) => {
            try {
                let url = '/admin/apps/registry';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                const apps = await invoke('GET', url);
                return apps || [];
            } catch (ex) {
                return [];
            }
        },

        deleteFromRegistry: async (data) => {
            try {
                await invoke('DELETE', '/admin/apps/registry', data);
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchLeads: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/leads/website';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                const leads = await invoke('GET', url);
                return leads || [];
            } catch (ex) {
                return [];
            }
        },
        updateLead: async (id, data) => {
            try {
                return await invoke('POST', `/admin/leads/website/${id}`, trimSpaces(data));
            } catch (ex) {
                return false;
            }
        },
        deleteLead: async (id) => {
            try {
                return await invoke('DELETE', `/admin/leads/website/${id}`);
            } catch (ex) {
                return false;
            }
        },
    },

    members: {
        fetchMembers: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/members';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                const members = await invoke('GET', url);
                return members || [];
            } catch (ex) {
                return [];
            }
        },
        updateMember: async (memberId, data) => {
            try {
                const params = trimSpaces(data);
                params.userVerificationUri = `${nest.baseUrl}/auth/email/change`; // ?code=ABSGD
                return await invoke('POST', `/admin/members/${memberId}`, params);
            } catch (ex) {
                return null;
            }
        },
        blockMember: async (memberId) => {
            try {
                return await invoke('POST', `/admin/members/block/${memberId}`);
            } catch (ex) {
                return null;
            }
        },
        unblockMember: async (memberId) => {
            try {
                return await invoke('POST', `/admin/members/unblock/${memberId}`);
            } catch (ex) {
                return null;
            }
        },
    },

    users: {
        fetchUsers: async () => {
            try {
                const users = await invoke('GET', '/admin/auth/users');
                return users || [];
            } catch (ex) {
                return [];
            }
        },

        fetchUser: async (username) => {
            try {
                const user = await invoke('GET', `/admin/auth/users/user/${trimSpaces(username)}`);
                return user;
            } catch (ex) {
                return null;
            }
        },

        createUser: async (params) => {
            try {
                const user = await invoke('POST', '/admin/auth/users', trimSpaces(params));
                return user;
            } catch (ex) {
                return null;
            }
        },
        editUser: async (username, params) => {
            try {
                await invoke('POST', `/admin/auth/users/user/${username}`, trimSpaces(params));
                return true;
            } catch (ex) {
                return null;
            }
        },
    },

    logs: {
        fetch: async (type, query = { page: 0, count: 10 }, downloadCSV = false) => {
            try {
                let url = `/admin/logs/${type}${downloadCSV ? '/export' : ''}`;
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
    },

    teams: {
        fetchTeams: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/teams';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
        updateTeam: async (teamId, params) => {
            try {
                return await invoke('POST', `/admin/teams/${teamId}`, trimSpaces(params));
            } catch (ex) {
                return null;
            }
        },
    },

    sms: {
        shortcode: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/shortcodes${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            create: async (username, shortCode, networks, type, price = 0, currency = 'KES') => {
                try {
                    const params = {
                        username,
                        shortCode,
                        networks,
                        type,
                        price,
                        currency,
                    };
                    await invoke('POST', '/admin/sms/shortcodes', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            update: async (id, userId, networks, type, shortCode, price = 0, currency = 'KES') => {
                try {
                    const params = {
                        userId,
                        networks,
                        type,
                        price,
                        shortCode,
                        currency,
                    };
                    await invoke('POST', `/admin/sms/shortcodes/shortcode/${id}`, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            remove: async (id, userId, shortCode) => {
                try {
                    await invoke('DELETE', `/admin/sms/shortcodes/shortcode/${id}?user=${userId}&shortCode=${shortCode}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },

        keywords: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/keywords${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            create: async (username, keyword, shortcode, stripped) => {
                try {
                    const params = {
                        username,
                        keyword,
                        shortcode,
                        stripped,
                    };
                    await invoke('POST', '/admin/sms/keywords', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            remove: async (id, userId) => {
                try {
                    await invoke('DELETE', `/admin/sms/keywords/keyword/${id}?user=${userId}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },

        alphanumerics: {

            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/alphanumerics${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            create: async (username, alphanumeric) => {
                try {
                    const params = {
                        username,
                        alphanumeric,
                    };
                    await invoke('POST', '/admin/sms/alphanumerics', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            remove: async (id, userId) => {
                try {
                    await invoke('DELETE', `/admin/sms/alphanumerics/alphanumeric/${id}?user=${userId}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            updateBlacklist: async (username, alphanumeric, phone, blocked) => {
                try {
                    const params = {
                        username,
                        alphanumeric,
                        phone,
                        blocked,
                    };
                    await invoke('POST', '/admin/sms/alphanumerics/blacklist', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            fetchConfigurations: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/alphanumerics/configs${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            addConfiguration: async (alphanumeric, network, config) => {
                try {
                    const url = '/admin/sms/alphanumerics/configs';
                    const params = {
                        alphanumeric,
                        network,
                        config,
                    };
                    await invoke('POST', url, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            deleteConfiguration: async (alphanumeric, network, config) => {
                try {
                    const url = `/admin/sms/alphanumerics/configs?network=${network}&alphanumeric=${alphanumeric}&config=${config}`;
                    await invoke('DELETE', url);
                    return true;
                } catch (err) {
                    return false;
                }
            },

            fetchConfigNetworks: async () => {
                try {
                    const url = '/admin/sms/alphanumerics/configs/networks';
                    return await invoke('GET', url);
                } catch (ex) {
                    return {};
                }
            },
        },

        smpp: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/smpp${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            create: async (username, service, port) => {
                try {
                    const params = {
                        username,
                        service,
                        port,
                    };
                    await invoke('POST', '/admin/sms/smpp', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            update: async (id, service, port, userId) => {
                try {
                    const params = {
                        service,
                        port,
                        userId,
                    };
                    await invoke('POST', `/admin/sms/smpp/${id}`, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            remove: async (id, userId, name) => {
                try {
                    await invoke('DELETE', `/admin/sms/smpp/${id}?user=${userId}&service=${name}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },

        premium: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/premium/subscriptions${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            create: async (username, shortCode, keyword) => {
                try {
                    const params = {
                        username,
                        shortCode,
                        keyword,
                    };
                    await invoke('POST', '/admin/sms/premium/subscriptions', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            update: async (id, username, shortCode) => {
                try {
                    const params = {
                        username,
                        shortCode,
                    };
                    await invoke('POST', `/admin/sms/premium/subscriptions/subscription/${id}`, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            remove: async (id, userId, shortCode) => {
                try {
                    await invoke('DELETE', `/admin/sms/premium/subscriptions/subscription/${id}?user=${userId}&shortCode=${shortCode}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            fetchCheckoutExemptions: async (query = { page: 0, count: 10 }) => {
                try {
                    let url = '/admin/sms/premium/subscriptions/checkout/exemptions';
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },

            addCheckoutExemption: async (shortCode) => {
                try {
                    const param = {
                        shortCode,
                    };
                    await invoke('POST', '/admin/sms/premium/subscriptions/checkout/exemptions', trimSpaces(param));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            deleteCheckoutExemption: async (shortCode) => {
                try {
                    await invoke('DELETE', `/admin/sms/premium/subscriptions/checkout/exemptions?shortCode=${shortCode}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            createSafaricomProduct: async (product) => {
                try {
                    const params = { ...product };
                    await invoke('POST', '/admin/sms/premium/safaricom/products', trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            fetchSafaricomProducts: async (query = { page: 0, count: 50 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/premium/safaricom/products${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (err) {
                    return [];
                }
            },

            getSafaricomProduct: async (productId) => {
                try {
                    return await invoke('GET', `/admin/sms/premium/safaricom/products/${productId}`);
                } catch (ex) {
                    return false;
                }
            },

            updateSafaricomProduct: async (productId, product) => {
                try {
                    const params = { ...product };
                    await invoke('POST', `/admin/sms/premium/safaricom/products/${productId}`, trimSpaces(params));
                    return true;
                } catch (ex) {
                    return false;
                }
            },

            // eslint-disable-next-line max-len
            fetchSubscriptionEvents: async (productId, query = { page: 0, count: 50 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/premium/safaricom/products/${productId}/events${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    return await invoke('GET', url);
                } catch (ex) {
                    return [];
                }
            },
        },

        fetchRequests: async (service, query = { page: 0, count: 50 }) => {
            try {
                let url = `/admin/sms/requests/${service}`;
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        updateRequest: async (service, id, data) => {
            try {
                await invoke('POST', `/admin/sms/requests/${service}/request/${id}`, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },
        billingOverride: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/sms/billing/override${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    const overrides = await invoke('GET', url);
                    return overrides || [];
                } catch (ex) {
                    return [];
                }
            },
            create: async (data) => {
                try {
                    return await invoke('POST', '/admin/sms/billing/override/', trimSpaces(data));
                } catch (ex) {
                    return null;
                }
            },
            update: async (id, data) => {
                try {
                    return await invoke('POST', `/admin/sms/billing/override/${id}`, trimSpaces(data));
                } catch (ex) {
                    return null;
                }
            },
            delete: async (id) => {
                try {
                    await invoke('DELETE', `/admin/sms/billing/override/${id}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },
    },

    ussd: {

        fetchServiceCodes: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
            try {
                let url = `/admin/ussd/codes${downloadCSV ? '/export' : ''}`;
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        // eslint-disable-next-line max-len
        createServiceCode: async (username, shortCode, countryCode, providers, channel = null, hasServiceChannel = false) => {
            try {
                const type = channel !== null ? 'shared' : 'dedicated';
                const url = `/admin/ussd/codes/${type}`;
                const data = {
                    username,
                    shortCode,
                    hasServiceChannel,
                };
                if (type === 'shared') {
                    data.channel = channel;
                } else if (type === 'dedicated') {
                    data.providers = providers;
                }
                data.countryCode = countryCode;
                await invoke('POST', url, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },

        updateServiceCode: async (id, countryCode, providers, userId, billing) => {
            try {
                const params = {
                    countryCode,
                    providers,
                    userId,
                    billing,
                };
                await invoke('POST', `/admin/ussd/codes/code/${id}`, trimSpaces(params));
                return true;
            } catch (ex) {
                return false;
            }
        },

        deleteCode: async (serviceCodeUserId, userId) => {
            try {
                await invoke('DELETE', `/admin/ussd/codes/code/${serviceCodeUserId}?user=${userId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchRequests: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/ussd/requests';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        updateRequest: async (id, data) => {
            try {
                await invoke('POST', `/admin/ussd/requests/request/${id}`, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },
        billingOverride: {
            fetch: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
                try {
                    let url = `/admin/ussd/billing/override${downloadCSV ? '/export' : ''}`;
                    query = trimSpaces(query); // eslint-disable-line
                    const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                    url = `${url}${params.replace(/&$/, '')}`;
                    const overrides = await invoke('GET', url);
                    return overrides || [];
                } catch (ex) {
                    return [];
                }
            },
            create: async (data) => {
                try {
                    return await invoke('POST', '/admin/ussd/billing/override/', trimSpaces(data));
                } catch (ex) {
                    return null;
                }
            },
            update: async (id, data) => {
                try {
                    return await invoke('POST', `/admin/ussd/billing/override/${id}`, trimSpaces(data));
                } catch (ex) {
                    return null;
                }
            },
            delete: async (id) => {
                try {
                    await invoke('DELETE', `/admin/ussd/billing/override/${id}`);
                    return true;
                } catch (ex) {
                    return false;
                }
            },
        },
    },

    chat: {
        fetchBots: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/chat/sinch';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                const sinchBots = await invoke('GET', url);

                url = url.replace('sinch', 'telegram');
                const telegramBots = await invoke('GET', url);

                return [
                    ...sinchBots.map((bot) => ({ ...bot, kind: 'Sinch' })),
                    ...telegramBots.map((bot) => ({ ...bot, kind: 'Telegram' })),
                ];
            } catch (ex) {
                return [];
            }
        },

        fetchProducts: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/chat/products';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        fetchTemplates: async (query = { page: 0, count: 10 }) => {
            try {
                let url = '/admin/chat/whatsapp/templates';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        createBot: async (kind, data) => {
            try {
                const url = `/admin/chat/${kind.toLowerCase()}`;
                return await invoke('POST', url, trimSpaces(data));
            } catch (ex) {
                return false;
            }
        },

        createTemplate: async (data) => {
            try {
                const url = '/admin/chat/whatsapp/templates';
                return await invoke('POST', url, trimSpaces(data));
            } catch (ex) {
                return false;
            }
        },

        createChannel: async (productId, data) => {
            try {
                const url = `/admin/chat/products/${productId}/channel`;
                return await invoke('POST', url, trimSpaces(data));
            } catch (ex) {
                return false;
            }
        },

        createProduct: async (data) => {
            try {
                const url = '/admin/chat/products';
                return await invoke('POST', url, trimSpaces(data));
            } catch (ex) {
                return false;
            }
        },

        deleteProduct: async (productId) => {
            try {
                await invoke('DELETE', `/admin/chat/products/${productId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        deleteProductChannel: async (productId, channelId) => {
            try {
                await invoke('DELETE', `/admin/chat/products/${productId}/channel/${channelId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        deleteTemplate: async (productId) => {
            try {
                await invoke('DELETE', `/admin/chat/whatsapp/templates/${productId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        deleteBot: async (bot) => {
            try {
                const url = `/admin/chat/${bot.kind.toLowerCase()}/${bot.id}`;
                await invoke('DELETE', url);
                return true;
            } catch (ex) {
                return false;
            }
        },
    },

    airtime: {
        fetchProducts: async (query = { page: 0, count: 100 }, downloadCSV = false) => {
            try {
                let url = `/admin/airtime/products${downloadCSV ? '/export' : ''}`;
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
        updateProduct: async (productId, username, enabled, productLimit) => {
            try {
                await invoke('POST', `/admin/airtime/products/product/${productId}`, { username, enabled, productLimit });
                return true;
            } catch (ex) {
                return false;
            }
        },
        createProduct: async (username, enabled, productLimit) => {
            try {
                await invoke('POST', '/admin/airtime/products', { username, enabled, productLimit });
                return true;
            } catch (ex) {
                return false;
            }
        },
        fetchNetworks: async (query = { sandbox: false }) => {
            try {
                let url = '/admin/airtime/networks';
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
        fetchDiscounts: async (query = { page: 0, count: 100 }, downloadCSV = false) => {
            try {
                let url = `/admin/airtime/discounts${downloadCSV ? '/export' : ''}`;
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
        updateDiscount: async (discountId, username, discount) => {
            try {
                await invoke('POST', `/admin/airtime/discounts/discount/${discountId}`, { username, discount });
                return true;
            } catch (ex) {
                return false;
            }
        },
        createDiscount: async (username, discounts) => {
            try {
                await invoke('POST', '/admin/airtime/discounts', { username, discounts });
                return true;
            } catch (ex) {
                return false;
            }
        },
        deleteDiscount: async (discountId) => {
            try {
                await invoke('DELETE', `/admin/airtime/discounts/discount/${discountId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },
    },

    payments: {

        fetchRequests: async (query = { page: 0, count: 100 }) => {
            try {
                let url = '/admin/payments/requests';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        updateRequest: async (id, data) => {
            try {
                await invoke('POST', `/admin/payments/requests/request/${id}`, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchProducts: async (query = { page: 0, count: 100 }, downloadCSV = false) => {
            try {
                let url = `/admin/payments/products${downloadCSV ? '/export' : ''}`;
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        createProduct: async (data) => {
            try {
                await invoke('POST', '/admin/payments/products', trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },

        deleteProduct: async (productId, userId) => {
            try {
                await invoke('DELETE', `/admin/payments/products/product/${productId}?user=${userId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        updateProduct: async (productId, name, userId, enabled, channels, allowMobileData) => {
            try {
                const data = {
                    name,
                    userId,
                    enabled,
                    channels,
                    allowMobileData,
                };
                await invoke('POST', `/admin/payments/products/product/${productId}`, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },
    },

    voice: {
        createPhone: async (data) => {
            try {
                return await invoke('POST', '/admin/voice/phones', trimSpaces(data));
            } catch (ex) {
                return null;
            }
        },

        updatePhone: async (id, data) => {
            try {
                await invoke('POST', `/admin/voice/phones/phone/${id}`, data);
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchPhones: async (query = { page: 0, count: 10 }, downloadCSV = false) => {
            try {
                let url = `/admin/voice/phones${downloadCSV ? '/export' : ''}`;
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },
        deletePhone: async (phoneId, userId) => {
            try {
                await invoke('DELETE', `/admin/voice/phones/phone/${phoneId}?user=${userId}`);
                return true;
            } catch (ex) {
                return false;
            }
        },

        fetchRequests: async (query = { count: 100, page: 10 }) => {
            try {
                let url = '/admin/voice/requests';
                query = trimSpaces(query); // eslint-disable-line
                const params = Object.keys(query).reduce((acc, param) => `${acc}${param}=${query[param]}&`, '?');
                url = `${url}${params.replace(/&$/, '')}`;
                return await invoke('GET', url);
            } catch (ex) {
                return [];
            }
        },

        updateRequest: async (id, data) => {
            try {
                await invoke('POST', `/admin/voice/requests/request/${id}`, trimSpaces(data));
                return true;
            } catch (ex) {
                return false;
            }
        },
    },
};
