import swal from 'sweetalert';

const handleExpiredSession = async () => {
    const state = swal.getState();
    if (state.isOpen) { return; }

    swal({
        title: 'Oops',
        text: 'Your session has expired, you need to login again!',
        icon: 'error',
        button: false,
    }).then(() => window.location.reload());
};

export default handleExpiredSession;
