const ApiKey = resolve => require(['./api_key/component.vue'], resolve); // eslint-disable-line
const Notification = resolve => require(['./notification/component.vue'], resolve); // eslint-disable-line
const ApiKeyView = resolve => require(['./api_key/apiKeyView.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/settings/key',
        name: 'settings-api',
        component: ApiKey,
        meta: {
            subtitle: 'API Key',
        },
    },

    {
        path: '/settings/key/generate',
        name: 'settings-api-generate',
        component: ApiKeyView,
        meta: {
            subtitle: 'API Key',
        },
    },

    {
        path: '/settings/notification',
        name: 'settings-balance-notification',
        component: Notification,
        meta: { subtitle: 'Balance Alert' },
    },
];
