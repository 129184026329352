/* eslint-disable import/no-dynamic-require, global-require */
const ListProducts = (resolve) => require(['./products/component.vue'], resolve);
const Analytics = (resolve) => require(['./analytics/component.vue'], resolve);
const Messages = (resolve) => require(['./messages/component.vue'], resolve);
/* eslint-enable import/no-dynamic-require, global-require */

const routes = [
    {
        path: '/chat/products', name: 'chat-products', component: ListProducts, meta: { title: 'Chat', subtitle: 'Products' },
    },
    {
        path: '/chat/messages', name: 'chat-messages', component: Messages, meta: { title: 'Chat', subtitle: 'Messages' },
    },
    {
        path: '/chat/analytics', name: 'chat-analytics', component: Analytics, meta: { title: 'Chat', subtitle: 'Analytics' },
    },
];

export default routes;
