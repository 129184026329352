const Phones = resolve => require(['./phones/component.vue'], resolve); // eslint-disable-line
const Sessions = resolve => require(['./sessions/component.vue'], resolve); // eslint-disable-line
const RequestVoice = resolve => require(['./request/component.vue'], resolve); // eslint-disable-line
const Analytics = resolve => require(['./analytics/component.vue'], resolve); // eslint-disable-line
const CreatePhone = resolve => require(['./sandbox/create/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/voice/phones', name: 'voice-phones', component: Phones, meta: { subtitle: 'Phones' },
    },
    {
        path: '/voice/sessions', name: 'voice-sessions', component: Sessions, meta: { subtitle: 'Sessions' },
    },
    { path: '/voice/request', name: 'voice-request', component: RequestVoice },
    {
        path: '/voice/analytics', name: 'voice-analytics', component: Analytics, meta: { subtitle: 'Analytics' },
    },

    // Sandbox
    { path: '/voice/phones/create', name: 'sandbox-voice-create-phone', component: CreatePhone },
];
