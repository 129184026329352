<template>
    <div class="row page-navigation-wrapper">
        <div class="medium-12 columns page-navigation">
            <template v-if="$route.name === 'dashboard'">
                <div class="name-wrapper">
                    <span><b>App Name:</b> {{ title }}</span>
                    <span><b>Username:</b> {{ subtitle }}</span>
                </div>
            </template>
            <template v-else>
                <h2 class="page-navigation__title show-for-medium">{{ title }} <template v-if="subtitle">- <small>{{ subtitle }}</small></template></h2>
                <h2 class="page-navigation__title show-for-small-only">{{ title }}<br/><small>{{ subtitle }}</small></h2>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({}),
    props: ['title', 'subtitle'],
};
</script>
