/* global Cookies, download, nest, appLoading */
import { jwtDecode } from 'jwt-decode';
import {
    getDomain,
    showNotification,
} from '../index';

export const COOKIES = {
    ADMIN_ALIAS: '_atgadal_', // 'admin.alias',
    TOKEN: '_atgat_', // tokens
    NOTIFICATIONBANNER: '_atnotification_', // notification banner
    getExpirationTime() {
        return new Date(new Date().getTime() + (nest.login.cookieExpiresInHours * 60 * 60 * 1000));
    },
};

export default async function invoke(
    method,
    path,
    data = undefined,
    options = {
        noAuth: undefined,
        noAlias: undefined,
        noLoading: undefined,
        timeout: 50000,
        headers: {},
        hasFormData: false,
        useMarketplaceApi: false,
    },
) {
    const { useMarketplaceApi } = options;
    const useAuth = options.noAuth !== true;

    let opts;

    if (!useMarketplaceApi) {
        const headers = {
            'X-Client-Id': nest.clientId,
        };

        if (useAuth) {
            headers.Authorization = `Bearer ${Cookies.get(COOKIES.TOKEN)}`; // FIXME: Keep token in memory?
        }

        const aliasToken = Cookies.get(COOKIES.ADMIN_ALIAS);
        if (aliasToken && options.noAlias !== true) {
            headers.Authorization = `Bearer ${aliasToken}`;
        }

        if (options.headers) {
            Object.keys(options.headers)
                .forEach((key) => {
                    headers[key] = options.headers[key];
                });
        }

        opts = {
            method,
            // mode: 'cors',
            headers,
        };
    } else {
        opts = {
            method,
            headers: {

                ...options.headers,
                authorization: `Bearer ${nest.marketplace.apiKeys[options.useAdminAuth ? 'admin' : 'user']}`,
            },
        };
    }

    opts.body = data;
    if (data) {
        if (options.hasFormData) {
            opts.body = data;
        } else {
            opts.headers['Content-Type'] = 'application/json';
            opts.body = JSON.stringify(data);
        }
    }

    if (!options.noLoading) appLoading.start();

    let res = {};
    try {
        const baseUrl = useMarketplaceApi ? nest.marketplace.apiUrl : nest.apiUrl;
        // FIXME: To proper escaping
        const url = `${baseUrl}${path}`
            .replace(/\+/, '%2B')
            .replace(/ /, '%20')
            .replace(/#/, '%23');

        const timeout = options.timeout || 50000;

        const warningTimeout = setTimeout(() => {
            showNotification('Hmm...this seems to be taking longer than expected.', 'warning');
        }, 25000);

        res = await Promise.race([
            fetch(url, opts),
            new Promise((resolve, reject) => {
                setTimeout(() => reject(new Error('timeout')), timeout);
            }),
        ]);

        clearTimeout(warningTimeout);

        if (!options.noLoading) appLoading.stop();
    } catch (ex) {
        if (!options.noLoading) appLoading.stop();
        if (ex.message === 'timeout') {
            nest.last_kmsg = 'Request timed out, please check your connection.';
            throw ex;
        }
    }

    if (!(res.status >= 200 && res.status < 300)) {
        if (!useMarketplaceApi) {
            // force logout
            if (res.status === 401 && useAuth) {
                const user = (() => {
                    let token = Cookies.get(COOKIES.ADMIN_ALIAS);
                    if (!token) {
                        token = Cookies.get(COOKIES.TOKEN);
                    }
                    try {
                        return jwtDecode(token);
                    } catch (ex) {
                        return null;
                    }
                })();
                Cookies.remove(COOKIES.ADMIN_ALIAS);
                Cookies.remove(COOKIES.TOKEN, { domain: getDomain() });
                nest.vm.$emit(nest.events.sessionExpired, user.isAlias ? null : user);
                return null;
            }
        }

        // do we have a message?
        try {
            const response = await res.json();
            nest.last_kmsg = response.message || response.data;
        } catch (ex) { /* ignore */ }

        throw res.statusText;
    }

    if (useMarketplaceApi) {
        const result = await res.json();
        return result.data;
    }

    if (!/\/export/.test(path)) { // assumes paths with no /export are not downloads
        const result = await res.json();
        if (result.metadata) {
            return {
                data: result.data,
                metadata: result.metadata,
            };
        }
        return result.data;
    }
    const blob = await res.blob();
    let filename = res.headers.get('Content-disposition').split(';');
    filename = (filename[1]).trim().split('=')[1].trim();
    appLoading.start();
    await download(blob, filename, blob.type);
    appLoading.stop();
    return true;
}
