<template>
    <aside class="js-side-nav side-nav side-nav--visible">
        <nav class="js-side-nav__container side-nav__container">

            <header class="side-nav__header">
                 <div class="side-nav__header-logo-wrapper clearfix show-for-medium">
                    <i class="js-header__sidenav-close ti-menu side-nav__header-icon"></i>
                    <a href="/" class="side-nav__header-logo-link"><img class="side-nav__header-logo" src="/assets/img/logos/logo-full-color.png" /></a>
                </div>
                <div class="show-for-small-only">
                    <div class="side-nav__header-title">
                        <img class="side-nav__header-title-logo" src="/assets/img/logos/at-logo-icon.png" />
                        <span class="side-nav__header-title-text">Africa's Talking</span>
                    </div>
                    <div class="side-nav__header-content">
                        <span class="side-nav__user"><i class="ti-user"></i>{{user.username}}</span>
                    </div>
                </div>
            </header>

            <ul class="side-nav__content">
                <li>
                    <a href="#" id="nav-home" class="side-nav__menu-item no-more">
                        <router-link :to="{name:'admin-home'}" tag="div">
                            <i class="ti-home"></i>Dashboard
                        </router-link>
                    </a>
                </li>
                <li>
                    <a href="#" id="nav-product-requests" class="side-nav__menu-item no-more">
                        <router-link :to="{ name:'admin-list-product-requests' }" tag="div">
                            <i class="ti-write"></i>Product Requests
                        </router-link>
                    </a>
                </li>
                <li>
                    <a href="#" id="nav-sms" class="side-nav__menu-item more"><i class="ti-mobile"></i>SMS</a>
                    <ul class="side-nav__content level-1">

                        <!-- <li>
                            <a class="side-nav__menu-item more" href="#" id="nav-airtime">Aritime</a>
                                <ul class="side-nav__content level-2">
                                    <li>
                                        <a class="side-nav__menu-item more" href="#" id="nav-airtime-optout">Users</a>
                                        <ul class="side-nav__content level-3">
                                            <li><router-link :to="{name:'admin-sms-list-optout'}" class="side-nav__menu-item">List</router-link></li>
                                            <li><router-link :to="{name:'admin-sms-update-optout'}" class="side-nav__menu-item">Update</router-link></li>
                                        </ul>
                                    </li>
                                    
                                </ul>
                        </li> -->

                        <li>
                            <a class="side-nav__menu-item more" href="#" id="nav-alphanumerics">Alphanumerics</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name:'admin-sms-list-alphanumerics' }" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name:'admin-sms-create-alphanumerics' }" class="side-nav__menu-item">Create</router-link></li>
                                <li><router-link :to="{ name:'admin-sms-blacklist-alphanumerics' }" class="side-nav__menu-item">Modify Blacklist</router-link></li>
                                <li>
                                    <a class="side-nav__menu-item more" href="#" id="nav-alphanumerics-configurations">Configurations</a>
                                    <ul class="side-nav__content level-3">
                                        <li><router-link :to="{name:'admin-sms-list-configurations-alphanumerics'}" class="side-nav__menu-item">List</router-link></li>
                                        <li><router-link :to="{name:'admin-sms-update-configurations-alphanumerics'}" class="side-nav__menu-item">Update</router-link></li>
                                    </ul>
                                </li>
                                <li>
                                    <router-link :to="{ name: 'admin-sms-requests', params: { service: 'alphanumerics' } }" class="side-nav__menu-item">
                                        Requests
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-keywords">Keywords</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name:'admin-sms-list-keywords' }" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name:'admin-sms-create-keywords' }" class="side-nav__menu-item">Create</router-link></li>
                                <li>
                                    <router-link :to="{ name: 'admin-sms-requests', params: { service: 'keywords' } }" class="side-nav__menu-item">
                                        Requests
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a href="#" class="side-nav__menu-item more" id="nav-shortcodes">Shortcodes</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name:'admin-sms-list-shortcodes' }" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name:'admin-sms-create-shortcode' }" class="side-nav__menu-item">Create</router-link></li>
                                <li>
                                    <router-link :to="{ name: 'admin-sms-requests', params: { service: 'shortcodes' } }" class="side-nav__menu-item">
                                        Requests
                                    </router-link>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a class="side-nav__menu-item more" href="#" id="nav-products">Products</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{name:'admin-sms-list-products'}" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{name:'admin-sms-create-product'}" class="side-nav__menu-item">Create</router-link></li>
                                <li>
                                    <a class="side-nav__menu-item more" href="#" id="nav-products-checkout-exemptions">Checkout Exempted</a>
                                    <ul class="side-nav__content level-3">
                                        <li><router-link :to="{name:'admin-sms-list-checkout-exemptions'}" class="side-nav__menu-item">List</router-link></li>
                                        <li><router-link :to="{name:'admin-sms-create-checkout-exemptions'}" class="side-nav__menu-item">Create</router-link></li>
                                    </ul>
                                </li>
                                <li>
                                    <a class="side-nav__menu-item more" href="#" id="nav-products-safaricom">Safaricom</a>
                                    <ul class="side-nav__content level-3">
                                        <li><router-link :to="{name:'admin-sms-list-safaricom-products'}" class="side-nav__menu-item">List</router-link></li>
                                        <li><router-link :to="{name:'admin-sms-create-safaricom-product'}" class="side-nav__menu-item">Create</router-link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>

                        <li>
                            <a class="side-nav__menu-item more" href="#" id="nav-smpp">SMPP</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name:'admin-sms-list-smpp' }" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name:'admin-sms-create-smpp' }" class="side-nav__menu-item">Create</router-link></li>
                            </ul>
                        </li>

                        <li v-if="superAdmin">
                            <a class="side-nav__menu-item more" href="#" id="nav-sms-billing-override">Billing Override</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name: 'admin-sms-billing-override-list'}" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name: 'admin-sms-billing-override-create'}" class="side-nav__menu-item">Create</router-link></li>
                            </ul>
                        </li> 
                    </ul>
                </li>
                <li>
                    <a href="#" id="nav-chat" class="side-nav__menu-item more"><i class="ti-comments"></i>Chat <span class="badge success">BETA</span></a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{name:'admin-chat-bots'}" class="side-nav__menu-item">Bots</router-link></li>
                        <li><router-link :to="{name:'admin-chat-products'}" class="side-nav__menu-item">Products</router-link></li>
                        <li><router-link :to="{name:'admin-chat-channels'}" class="side-nav__menu-item">Channels</router-link></li>
                        <li><router-link :to="{name:'admin-chat-templates'}" class="side-nav__menu-item">Templates</router-link></li>
                    </ul>
                </li>
                <li>
                    <a id="nav-voice" class="side-nav__menu-item more" href="#"><i class="ti-announcement"></i>Voice</a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{ name: 'admin-voice-phones' }" class="side-nav__menu-item">List</router-link></li>
                        <li><router-link :to="{ name: 'admin-voice-create-phone' }" class="side-nav__menu-item">Create</router-link></li>
                        <li><router-link :to="{ name: 'admin-voice-requests' }" class="side-nav__menu-item" >Requests</router-link></li>
                    </ul>
                </li>
                <li>
                    <a href="#" id="nav-ussd" class="side-nav__menu-item more"><i class="ti-settings"></i>USSD</a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{name:'admin-ussd-list-codes'}" class="side-nav__menu-item">List</router-link></li>
                        <li><router-link :to="{name:'admin-ussd-create-code'}" class="side-nav__menu-item">Create</router-link></li>
                        <li><router-link :to="{name:'admin-ussd-requests'}" class="side-nav__menu-item">Requests</router-link></li>
                        <li v-if="superAdmin">
                            <a class="side-nav__menu-item more" href="#" id="nav-ussd-billing-override">Billing Override</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name: 'admin-ussd-billing-override-list'}" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name: 'admin-ussd-billing-override-create'}" class="side-nav__menu-item">Create</router-link></li>
                            </ul>
                        </li> 
                    </ul>
                </li>
                <li>
                    <a href="#" id="nav-airtime" class="side-nav__menu-item more"><i class="ti-receipt"></i>Airtime</a>
                    <ul class="side-nav__content level-1">
                      <li><router-link :to="{name:'admin-airtime-products-list'}" class="side-nav__menu-item">List</router-link></li>
                      <li><router-link :to="{name:'admin-airtime-products-create'}" class="side-nav__menu-item">Create</router-link></li>
                      <li v-if="superAdmin">
                            <a class="side-nav__menu-item more" href="#" id="nav-sms-billing-override">Billing Override</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name: 'admin-airtime-discounts-list'}" class="side-nav__menu-item">List</router-link></li>
                                <li><router-link :to="{ name: 'admin-airtime-discounts-create'}" class="side-nav__menu-item">Create</router-link></li>
                            </ul>
                        </li> 
                    </ul>
                </li>
                <li>
                    <a id="nav-payments" class="side-nav__menu-item more" href="#"><i class="ti-money"></i>Payments</a>
                    <ul class="side-nav__content level-1">
                      <li><router-link :to="{name:'admin-payments-products'}" class="side-nav__menu-item">List</router-link></li>
                        <li><router-link :to="{name:'admin-payments-create-product'}" class="side-nav__menu-item">Create</router-link></li>
                        <li><router-link :to="{name:'admin-payments-requests'}" class="side-nav__menu-item">Requests</router-link></li>

                    </ul>
                </li>
                <li>
                    <a id="nav-manage-users" class="side-nav__menu-item more" href="#"><i class="ti-user"></i>User Management</a>
                        <ul class="side-nav__content level-1">
                            <li><router-link :to="{name:'admin-members'}" class="side-nav__menu-item">Members</router-link></li>
                            <li v-if="superAdmin"><router-link :to="{name:'admin-teams'}" class="side-nav__menu-item"> Teams </router-link></li>
                            <a class="side-nav__menu-item more" href="#" id="nav-applications">Applications</a>
                                <ul class="side-nav__content level-2">
                                    <li><router-link :to="{name:'admin-apps'}" class="side-nav__menu-item"> List </router-link></li>
                                    <li v-if="superAdmin">
                                        <a class="side-nav__menu-item more" href="#" id="nav-creditworthy">Creditworthy</a>
                                        <ul class="side-nav__content level-3">
                                            <li><router-link :to="{ name: 'admin-creditworthy-list'}" class="side-nav__menu-item">List</router-link></li>
                                            <li><router-link :to="{ name: 'admin-creditworthy-create'}" class="side-nav__menu-item">Create</router-link></li>
                                        </ul>
                                        <a class="side-nav__menu-item more" href="#" id="nav-creditworthy">M-Pesa Invoice</a>
                                        <ul class="side-nav__content level-3">
                                            <li><router-link :to="{ name: 'admin-mpesa-config-list'}" class="side-nav__menu-item">List</router-link></li>
                                            <li><router-link :to="{ name: 'admin-mpesa-config-create'}" class="side-nav__menu-item">Create</router-link></li>
                                        </ul>
                                    </li>
                                </ul>
                        </ul>
                </li>
                <li>
                    <a id="nav-manage-content" class="side-nav__menu-item more" href="#">
                        <i class="ti-pencil-alt"></i>Manage Content
                    </a>
                    <ul class="side-nav__content level-1">
                        <li><router-link :to="{name:'admin-manage-marketplace-listings'}" class="side-nav__menu-item">Marketplace Listings</router-link></li>
                    </ul>
                </li>
                <li>
                    <a id="nav-admin" class="side-nav__menu-item more" href="#"><i class="ti-crown"></i>Administration</a>
                    <ul class="side-nav__content level-1">
                        <li>
                            <a class="side-nav__menu-item more" href="#" id="nav-admin-account">Account</a>
                            <ul class="side-nav__content level-2">
                                <li><router-link :to="{ name:'two-fa-update' }" class="side-nav__menu-item" id="nav-admin-two-fa">Change 2FA</router-link></li>
                                <li><router-link :to="{ name:'password' }" class="side-nav__menu-item" id="nav-admin-password">Change Password</router-link></li>
                                <li v-if="superAdmin"><router-link :to="{ name: 'admin-users' }" class="side-nav__menu-item" href="#" id="nav-admin-users">Administrators</router-link></li>
                            </ul>
                        </li>
                      <li v-if="superAdmin">
                        <a class="side-nav__menu-item more" href="#" id="nav-logs">Logs</a>
                        <ul class="side-nav__content level-2">
                          <li><router-link :to="{ name: 'admin-logs-payments'}" class="side-nav__menu-item">Payments</router-link></li>
                          <li><router-link :to="{ name: 'admin-logs-services'}" class="side-nav__menu-item">Services</router-link></li>
                          <li><router-link :to="{ name: 'admin-logs-configs'}" class="side-nav__menu-item">Configs</router-link></li>
                        </ul>
                      </li>
                      <li v-if="intermediateAdmin || superAdmin">
                        <a class="side-nav__menu-item more" href="#" id="nav-payments-wallet">Payment Wallet</a>
                        <ul class="side-nav__content level-2">
                          <li><router-link :to="{name:'admin-wallet-payment', params: {type: 'topup'} }" class="side-nav__menu-item">Topup</router-link></li>
                          <li><router-link :to="{name:'admin-wallet-payment', params: {type: 'refund'} }" class="side-nav__menu-item">Refund</router-link></li>
                          <li><router-link :to="{name:'admin-wallet-payment', params: {type: 'replay'} }" class="side-nav__menu-item">Replay</router-link></li>
                        </ul>
                      </li>
                      <li v-if="intermediateAdmin || superAdmin">
                        <a class="side-nav__menu-item more" href="#" id="nav-api-wallet">API Wallet</a>
                        <ul class="side-nav__content level-2">
                          <li><router-link :to="{name:'admin-wallet-api-mpesa'}"  class="side-nav__menu-item">Topup M-Pesa</router-link></li>
                          <li><router-link :to="{name:'admin-wallet-api-cheque'}" class="side-nav__menu-item">Topup Cheque</router-link></li>
                          <li><router-link :to="{name:'admin-wallet-api-refund'}" class="side-nav__menu-item">Add Refund</router-link></li>
                        </ul>
                      </li>
                      <li>
                          <a class="side-nav__menu-item more" href="#" id="nav-admin-tests">Test SMS</a>
                          <ul class="side-nav__content level-2">
                              <li><router-link :to="{name:'admin-test-sms'}" class="side-nav__menu-item">Send</router-link></li>
                              <li><router-link :to="{name:'admin-test-premium-subscription'}" class="side-nav__menu-item">Premium Subscriptions</router-link></li>
                          </ul>
                      </li>
                      <li v-if="superAdmin">
                        <a class="side-nav__menu-item more" href="#" id="all-overrides">Gateway Overrides</a>
                        <ul class="side-nav__content level-2">
                            <span>
                                <a class="side-nav__menu-item more" href="#" id="nav-sms-routing-override">SMS
                                    Routing
                                    Override</a>
                                <ul class="side-nav__content level-3">
                                    <li>
                                        <router-link :to="{name:'admin-sms-routing-override-list'}"
                                            class="side-nav__menu-item">List</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'admin-sms-routing-override-create'}"
                                            class="side-nav__menu-item">Create</router-link>
                                    </li>
                                </ul>
                            </span>

                            <span>
                                <a class="side-nav__menu-item more" href="#"
                                    id="nav-airtime-gateway-override">Airtime
                                    Gateway Override</a>
                                <ul class="side-nav__content level-3">
                                    <li>
                                        <router-link :to="{name:'admin-airtime-gateway-override-list'}"
                                            class="side-nav__menu-item">List</router-link>
                                    </li>
                                    <li>
                                        <router-link :to="{name:'admin-airtime-gateway-override-create'}"
                                            class="side-nav__menu-item">Create</router-link>
                                    </li>
                                </ul>
                            </span>
                        </ul>
                    </li>

                    <li><router-link :to="{ name:'admin-leads' }" class="side-nav__menu-item">Client Leads</router-link></li>
                    <li>
                        <a class="side-nav__menu-item more" href="#" id="nav-email-jobs">Email Jobs</a>
                        <ul class="side-nav__content level-2">
                            <li><router-link :to="{ name:'expenditure-emails-list' }" class="side-nav__menu-item">Expenditures</router-link></li>
                            <li><router-link :to="{ name:'closing-balance-list' }" class="side-nav__menu-item">Closing Balances</router-link></li>
                        </ul>
                    </li>
                    </ul>
                </li>
            </ul>
        </nav>
    </aside>
</template>
<script>
 import { getUser, isIntermediateAdmin, isSuperAdmin } from '../../../utils/api/auth';

 export default {
     data: () => ({
         user: getUser(),
         superAdmin: isSuperAdmin(),
         intermediateAdmin: isIntermediateAdmin(),
     }),
 };
</script>
