const Transactions = resolve => require(['./transactions/component.vue'], resolve); // eslint-disable-line
const StatusCallback = resolve => require(['./callbacks/status/component.vue'], resolve); // eslint-disable-line
const Analytics = resolve => require(['./analytics/component.vue'], resolve); // eslint-disable-line
const RequestInsights = resolve => require(['./request/component.vue'], resolve); // eslint-disable-line

export default [
    {
        path: '/insights/transactions', name: 'insights-transactions', component: Transactions, meta: { subtitle: 'Transactions' },
    },
    {
        path: '/insights/callback/status', name: 'insights-callback', component: StatusCallback, meta: { subtitle: 'Status Callback' },
    },
    {
        path: '/insights/analytics', name: 'insights-analytics', component: Analytics, meta: { subtitle: 'Analytics' },
    },
    {
        path: '/insights/request', name: 'insights-request', component: RequestInsights,
    },
];
