const Notification = (initOptions) => {
    let ntf;
    let dismissttl;
    let active = false;

    let actionPositive;
    let actionNegative;
    let closeNtf;

    let handlePositiveAction;
    let handleNegativeAction;
    let handleCloseAction;

    let options = {
        wrapper: document.querySelector('.js-ntf-inner-wrapper'),
        message: 'Yo',
        type: 'success',
        actions: {},
        onOpen: () => false,
        onClose: () => false,
    };

    const onTransitionEnd = () => {
        ntf.classList.remove('ntf--animatable');
        ntf.removeEventListener('transitionend', onTransitionEnd);
    };

    const show = () => {
        active = true;

        ntf.classList.add('ntf--animatable');
        ntf.classList.add('ntf--show');
        ntf.addEventListener('transitionend', onTransitionEnd);

        options.onOpen();
    };

    const removeEventListeners = () => {
        if (actionPositive !== null) {
            actionPositive.removeEventListener('click', handlePositiveAction);
        }

        if (actionNegative !== null) {
            actionNegative.removeEventListener('click', handleNegativeAction);
        }

        if (closeNtf !== null) {
            closeNtf.removeEventListener('click', handleCloseAction);
        }
    };

    const dismiss = () => {
        active = false;

        if (dismissttl !== undefined) {
            clearTimeout(dismissttl);
        }

        removeEventListeners();
        options.wrapper.removeChild(ntf);
        options.onClose();
    };

    handlePositiveAction = () => {
        options.actions.positive.callback();
        dismiss();
    };

    handleNegativeAction = () => {
        options.actions.negative.callback();
        dismiss();
    };

    handleCloseAction = () => {
        dismiss();
    };

    const addEventListeners = () => {
        if (actionPositive !== null) {
            actionPositive.addEventListener('click', handlePositiveAction);
        }

        if (actionNegative !== null) {
            actionNegative.addEventListener('click', handleNegativeAction);
        }

        if (closeNtf !== null) {
            closeNtf.addEventListener('click', handleCloseAction);
        }
    };

    const createNtf = () => {
        const hasActions = Object.keys(options.actions).length === 2;

        ntf = document.createElement('div');
        ntf.className = `medium-5 medium-centered columns ntf-body ${options.type}`;

        if (hasActions) {
            ntf.classList.add('action');
            ntf.innerHTML = `<span class="message">${options.message}</span>
                             <div class="action-wrapper clearfix">
                             <button class="js-action-negative button ntf-action float-right">${options.actions.negative.btnText}</button>
                             <button class="js-action-positive button ntf-action float-right">${options.actions.positive.btnText}</button>
                             </div>`;
        } else {
            ntf.innerHTML = `<i class="ti-close ntf-close"></i>
                        <span class="message">${options.message}</span>`;
        }

        options.wrapper.insertBefore(ntf, options.wrapper.firstChild);

        // Set possible event elements
        actionPositive = ntf.querySelector('.js-action-positive');
        actionNegative = ntf.querySelector('.js-action-negative');
        closeNtf = ntf.querySelector('.ntf-close');

        if (options.ttl !== 0) {
            dismissttl = setTimeout(() => {
                if (active) dismiss();
            }, options.ttl);
        }

        addEventListeners();
    };

    options = Object.assign({}, options);
    Object.assign(options, initOptions);

    createNtf();

    return { show, dismiss };
};

window.Notification = Notification;
