import invoke from './invoke';
import { trimSpaces } from '../filters';

export async function getTeams() {
    try {
        return await invoke('GET', '/teams');
    } catch (ex) {
        return false;
    }
}

export async function createTeam(data) {
    try {
        return await invoke('POST', '/teams', trimSpaces(data));
    } catch (ex) {
        return false;
    }
}

export async function updateTeam(teamId, data) {
    try {
        return await invoke('POST', `/teams/${teamId}`, trimSpaces(data));
    } catch (ex) {
        return false;
    }
}

export async function removeTeam(teamId, password) {
    try {
        await invoke('POST', `/teams/${teamId}/remove`, { password });
        return true;
    } catch (ex) {
        return false;
    }
}

export async function addTeamMember(teamId, data) {
    try {
        await invoke('POST', `/teams/${teamId}/members`, trimSpaces(data));
        return true;
    } catch (ex) {
        return false;
    }
}

export async function updateTeamMemberAccessLvl(teamId, memberId, accessLevel) {
    try {
        await invoke('POST', `/teams/${teamId}/members/${memberId}/accesslvl`, { accessLevel });
        return true;
    } catch (ex) {
        return false;
    }
}

export async function updateTeamMemberDataVisibility(teamId, memberId, dataVisibility) {
    try {
        await invoke('POST', `/teams/${teamId}/members/${memberId}/datavisibility`, { dataVisibility });
        return true;
    } catch (ex) {
        return false;
    }
}

export async function removeTeamMember(teamId, memberId) {
    try {
        return await invoke('DELETE', `/teams/${teamId}/members/${memberId}`);
    } catch (ex) {
        return false;
    }
}

export async function sendInvite(teamId, data) {
    try {
        return await invoke('POST', `/teams/${teamId}/invites/`, trimSpaces(data));
    } catch (ex) {
        return false;
    }
}

export async function resendInvite(teamId, inviteCode) {
    try {
        await invoke('POST', `/teams/${teamId}/invites/${inviteCode}`);
        return true;
    } catch (ex) {
        return false;
    }
}

export async function removeInvite(teamId, inviteCode) {
    try {
        await invoke('DELETE', `/teams/${teamId}/invites/${inviteCode}`);
        return true;
    } catch (ex) {
        return false;
    }
}

export async function disableTeam(teamId) {
    try {
        return await invoke('POST', `/teams/${teamId}/disable`);
    } catch (ex) {
        return false;
    }
}

export async function createApp(teamId, data) {
    try {
        return await invoke('POST', `/teams/${teamId}/apps`, trimSpaces(data));
    } catch (ex) {
        return false;
    }
}

export async function updateApp(teamId, appId, data) {
    try {
        await invoke('POST', `/teams/${teamId}/apps/${appId}`, trimSpaces(data));
        return true;
    } catch (ex) {
        return false;
    }
}

export async function moveApp(teamId, appId, data) {
    try {
        await invoke('POST', `/teams/${teamId}/apps/${appId}/move`, trimSpaces(data));
        return true;
    } catch (ex) {
        return false;
    }
}

export async function enableApp(teamId, appId, password = undefined, code = undefined) {
    try {
        await invoke('POST', `/teams/${teamId}/apps/${appId}/enable`, { password, code });
        return true;
    } catch (ex) {
        return false;
    }
}

export async function disableApp(teamId, appId, password) {
    try {
        return await invoke('POST', `/teams/${teamId}/apps/${appId}/disable`, { password });
    } catch (ex) {
        return false;
    }
}
