const mountHeaderActions = () => {
    const mountAvatarMenu = () => {
        const avatarMenuTrigger = document.querySelector('.js-header__avatar');
        const avatarMenu = document.querySelector('.js-header__avatar__menu');

        let avatarMenuOpen = false;

        const hideAction = (evt) => {
            try {
                if (/avatar/g.test(evt.target.className)) return;
            } catch (err) { return; }

            avatarMenu.classList.remove('header__avatar__menu--visible');
            document.removeEventListener('click', hideAction);

            avatarMenuOpen = false;
        };

        const showAction = (evt) => {
            evt.stopImmediatePropagation();

            if (!avatarMenuOpen) {
                avatarMenu.classList.add('header__avatar__menu--visible');
                document.addEventListener('click', hideAction);

                avatarMenuOpen = true;
            } else {
                hideAction();
            }
        };

        const addEventListeners = () => {
            if (avatarMenuTrigger) {
                avatarMenuTrigger.addEventListener('click', showAction);
            }
        };

        addEventListeners();
    };

    mountAvatarMenu();
};

export default mountHeaderActions;
