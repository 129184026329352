import { isSmallestViewport, getCurrentApp } from '../../utils';
import { isLoggedIn, isAdmin } from '../../utils/api/auth';

import mountSideNav from '../../../assets/js/sideNav'; // eslint-disable-line

import AdminSidebar from './partials/admin.vue';
import AppSidebar from './partials/app.vue';
import UserSidebar from './partials/user.vue';

export default {
    name: 'sidebar',

    data: () => ({
        loggedIn: isLoggedIn(),
        isShowingApps: getCurrentApp(),
        isAdmin: isAdmin(),
    }),

    created: function fx() {
        this.loggedIn = isLoggedIn(this.isAdmin);

        this.isShowingApps = getCurrentApp(); // should not be undefined || null

        this.$router.beforeEach((to, from, next) => {
            // hide sibebar on mobile on route change
            if (isSmallestViewport) {
                document.querySelector('.js-side-nav').click();
            }
            next();
        });
    },

    mounted: function fx() {
        mountSideNav();
    },

    components: {
        'user-sidebar': UserSidebar,
        'app-sidebar': AppSidebar,
        'admin-sidebar': AdminSidebar,
    },
    watch: {
        $route: 'updateState',
    },

    updated: function fx() {
        this.updateState();
    },

    methods: {
        updateState: function fx() {
            const to = this.$route;

            let id = null;
            let l2Id = null;
            let l3Id = null;
            switch (to.name) { // Active menus...
            case 'dashboard':
                id = 'nav-dashboard';
                break;
            case 'create-product-request':
            case 'list-product-requests':
            case 'view-product-request':
            case 'edit-product-request':
                id = 'nav-product-requests';
                break;
            case 'contact':
                id = 'nav-contact';
                break;
            case 'teams':
                id = 'nav-home';
                break;
            case 'connections':
            case 'email':
            case 'password':
                if (isAdmin()) {
                    id = 'nav-admin';
                    l2Id = 'nav-admin-account';
                    l3Id = 'nav-admin-password';
                    break;
                }
                id = 'nav-user-settings';
                break;
            case 'two-fa-update':
                if (isAdmin()) {
                    id = 'nav-admin';
                    l2Id = 'nav-admin-account';
                    l3Id = 'nav-admin-two-fa';
                }
                break;
            case 'profile':
            case 'account':
                id = 'nav-user-settings';
                break;
            case 'sms-bulk-callback':
            case 'sms-premium-callback':
            case 'sms-inbox-callback':
            case 'sms-dlr-callback':
                id = 'nav-sms';
                l2Id = 'nav-sms-callback';
                break;
            case 'sms-bulk-outbox':
            case 'sms-bulk-analytics':
                id = 'nav-sms';
                l2Id = 'nav-sms-bulk';
                break;
            case 'sms-premium-outbox':
            case 'sms-premium-subscriptions':
            case 'sms-premium-analytics':
            case 'sms-premium-create':
                id = 'nav-sms';
                l2Id = 'nav-sms-premium';
                break;

            case 'sms-shortcodes':
            case 'sms-shortcodes-request':
            case 'sms-shortcodes-create':
                id = 'nav-sms';
                l2Id = 'nav-sms-shortcodes';
                break;
            case 'sms-alpha':
            case 'sms-alpha-create':
            case 'sms-alpha-request':
                id = 'nav-sms';
                l2Id = 'nav-sms-alpha';
                break;
            case 'sms-keywords':
            case 'sms-keywords-request':
                id = 'nav-sms';
                l2Id = 'nav-sms-keywords';
                break;
            case 'sms-inbox':
            case 'sms-smpp-accounts':
                id = 'nav-sms';
                break;
            case 'airtime-transactions':
            case 'airtime-analytics':
                id = 'nav-airtime';
                break;
            case 'airtime-callback':
            case 'airtime-validation-callback':
                id = 'nav-airtime';
                l2Id = 'nav-airtime-callback';
                break;
            case 'insights-transactions':
            case 'insights-analytics':
                id = 'nav-insights';
                break;
            case 'insights-callback':
                id = 'nav-insights';
                l2Id = 'nav-insights-callback';
                break;
            case 'billing-payment':
            case 'billing-statement':
                id = 'nav-billing';
                break;
            case 'mobile-data-transactions':
            case 'mobile-data-products':
            case 'mobile-data-request':
            case 'mobile-data-products-channels':
            case 'sandbox-mobile-data-create-product':
            case 'sandbox-mobile-data-create-channel':
            case 'sandbox-mobile-data-topup':
            case 'mobile-data-analytics':
            case 'mobile-data-analytics-product':
                id = 'nav-mobile-data';
                break;
            case 'voice-phones':
            case 'voice-phones-edit':
            case 'voice-sessions':
            case 'voice-request':
            case 'voice-analytics':
            case 'sandbox-voice-create-phone':
                id = 'nav-voice';
                break;
            case 'ussd-request':
            case 'ussd-codes':
            case 'ussd-push':
            case 'ussd-sessions':
            case 'ussd-analytics':
            case 'sandbox-ussd-create':
                id = 'nav-ussd';
                break;
            case 'chat-request':
            case 'chat-products':
            case 'chat-messages':
            case 'chat-analytics':
                id = 'nav-chat';
                break;
            case 'settings-api':
            case 'settings-balance-notification':
                id = 'nav-settings';
                break;
                /* Admins */
            case 'admin-home':
                id = 'nav-home';
                break;

            case 'admin-list-product-requests':
            case 'admin-view-product-request':
            case 'admin-edit-product-request':
                id = 'nav-product-requests';
                break;

            case 'admin-sms-list-alphanumerics':
            case 'admin-sms-create-alphanumerics':
            case 'admin-sms-blacklist-alphanumerics':
            case 'admin-sms-list-configurations':
            case 'admin-sms-update-configurations':
                id = 'nav-sms';
                l2Id = 'nav-alphanumerics';
                if (/configurations/.test(to.name)) {
                    l3Id = 'nav-alphanumerics-configurations';
                }
                break;
            case 'admin-sms-list-shortcodes':
            case 'admin-sms-create-shortcode':
                id = 'nav-sms';
                l2Id = 'nav-shortcodes';
                break;
            case 'admin-sms-list-keywords':
            case 'admin-sms-create-keywords':
                id = 'nav-sms';
                l2Id = 'nav-keywords';
                break;
            case 'admin-sms-list-smpp':
            case 'admin-sms-create-smpp':
                id = 'nav-sms';
                l2Id = 'nav-smpp';
                break;
            case 'admin-sms-list-products':
            case 'admin-sms-create-product':
            case 'admin-sms-edit-product':
            case 'admin-sms-list-checkout-exemptions':
            case 'admin-sms-create-checkout-exemptions':
            case 'admin-sms-list-safaricom-products':
            case 'admin-sms-create-safaricom-product':
            case 'admin-sms-safaricom-product-subscription-events':
                id = 'nav-sms';
                l2Id = 'nav-products';
                if (/exemptions/.test(to.name)) {
                    l3Id = 'nav-products-checkout-exemptions';
                }
                if (/safaricom/.test(to.name)) {
                    l3Id = 'nav-products-safaricom';
                }
                break;
            case 'admin-sms-requests':
            case 'admin-sms-requests-update':
                id = 'nav-sms';
                l2Id = `nav-${to.params.service}`;
                break;
            case 'admin-sms-billing-override-list':
            case 'admin-sms-billing-override-edit':
            case 'admin-sms-billing-override-create':
                id = 'nav-sms';
                l2Id = 'nav-sms-billing-override';
                break;
            case 'admin-ussd-list-codes':
            case 'admin-ussd-create-code':
            case 'admin-ussd-edit-code':
            case 'admin-ussd-requests':
            case 'admin-ussd-requests-update':
                id = 'nav-ussd';
                break;
            case 'admin-chat-bots':
            case 'admin-chat-products':
            case 'admin-chat-channels':
            case 'admin-chat-templates':
                id = 'nav-chat';
                break;
            case 'admin-ussd-billing-override-list':
            case 'admin-ussd-billing-override-edit':
            case 'admin-ussd-billing-override-create':
                id = 'nav-ussd';
                l2Id = 'nav-ussd-billing-override';
                break;
            case 'admin-airtime-products-list':
            case 'admin-airtime-products-create':
            case 'admin-airtime-product-edit':
                id = 'nav-airtime';
                break;
            case 'admin-voice-phones':
            case 'admin-voice-create-phone':
            case 'admin-voice-phone-edit':
            case 'admin-voice-requests':
            case 'admin-voice-requests-update':
                id = 'nav-voice';
                break;
            case 'admin-payments-products':
            case 'admin-payments-create-product':
            case 'admin-payments-edit-product':
            case 'admin-payments-requests':
                id = 'nav-payments';
                break;
            case 'admin-wallet-payment':
                id = 'nav-admin';
                l2Id = 'nav-payments-wallet';
                break;
            case 'admin-wallet-api-mpesa':
            case 'admin-wallet-api-cheque':
            case 'admin-wallet-api-refund':
                id = 'nav-admin';
                l2Id = 'nav-api-wallet';
                break;
            case 'admin-teams':
            case 'admin-members':
                id = 'nav-manage-users';
                break;
            case 'admin-apps':
            case 'admin-creditworthy-list':
            case 'admin-creditworthy-create':
                id = 'nav-manage-users';
                l2Id = 'nav-applications';
                if (/creditworthy/.test(to.name)) {
                    l3Id = 'nav-creditworthy';
                }
                break;
            case 'admin-test-sms':
            case 'admin-test-premium-subscription':
                id = 'nav-admin';
                l2Id = 'nav-admin-tests';
                break;
            case 'closing-balance-list':
            case 'expenditure-emails-list':
                id = 'nav-admin';
                l2Id = 'nav-email-jobs';
                break;
            case 'admin-users':
            case 'admin-users-edit':
                id = 'nav-admin';
                l2Id = 'nav-admin-account';
                l3Id = 'nav-admin-users';
                break;
            case 'admin-logs-payments':
            case 'admin-logs-services':
                id = 'nav-admin';
                l2Id = 'nav-logs';
                break;
            case 'admin-leads':
                id = 'nav-admin';
                break;
            case 'admin-manage-marketplace-listings':
                id = 'nav-manage-content';
                break;
            default:
                id = null;
                break;
            }

            if (id !== null) {
                const currentPage = document.querySelector('.current-page');
                if (currentPage !== null) {
                    currentPage.classList.remove('current-page');
                }

                const currentOpen = document.querySelectorAll('.side-nav__menu-item--open');
                if (currentOpen) {
                    currentOpen.forEach((item) => {
                        item.classList.remove('side-nav__menu-item--open');
                    });
                }

                const selected = document.querySelector(`#${id}`);
                if (selected) {
                    const { classList } = selected;
                    classList.add('current-page');
                    if (!classList.contains('no-more')) {
                        classList.add('side-nav__menu-item--open');
                    }
                }

                if (l2Id) {
                    const l2IdElement = document.querySelector(`#${l2Id}`);
                    if (l2IdElement) {
                        l2IdElement.classList.add('side-nav__menu-item--open');
                    }
                }

                if (l3Id) {
                    const l3IdElement = document.querySelector(`#${l3Id}`);
                    if (l3IdElement) {
                        l3IdElement.classList.add('side-nav__menu-item--open');
                    }
                }
            }
        },
    },
};
