<template>
</template>
<script>
    export default {
        props: ['action', 'timeout'],
        data() {
            return {
                timer: null,
                defaultTimeout: 10000,
            };
        },
        mounted() {
            this.tick();
        },

        methods: {
            tick: async function fx() {
                const success = await this.action();
                clearTimeout(this.timer);
                if (success) {
                    this.timer = setTimeout(async () => {
                        await this.tick();
                    }, this.timeout || this.defaultTimeout);
                }
            },
        },

    };
</script>
