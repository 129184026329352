const countries = [
    {
        phone: 254,
        mcc: 639,
        code: 'KE',
        currencyCode: 'KES',
        name: 'Kenya',
        providers: [
            { code: 63902, name: 'Safaricom', alias: 'safaricom' },
            { code: 63903, name: 'Airtel', alias: 'airtel_ke' },
            { code: 63907, name: 'Orange', alias: 'orange_ke' },
            { code: 63999, name: 'Equitel', alias: 'equitel_ke' },
        ],
    },
    {
        phone: 256,
        mcc: 641,
        code: 'UG',
        currencyCode: 'UGX',
        name: 'Uganda',
        providers: [
            { code: 64101, name: 'Airtel', alias: 'airtel_ug' },
            { code: 64110, name: 'MTN', alias: 'mtn_ug' },
            { code: 64111, name: 'UTL', alias: 'utl_ug' },
            { code: 64114, name: 'Africell', alias: 'africell_ug' },
        ],
    },
    {
        phone: 265,
        mcc: 650,
        code: 'MW',
        currencyCode: 'MWK',
        name: 'Malawi',
        providers: [
            { code: 65001, name: 'TNM' },
            { code: 65010, name: 'Airtel' },
        ],
    },
    {
        phone: 234,
        mcc: 621,
        code: 'NG',
        currencyCode: 'NGN',
        name: 'Nigeria',
        providers: [
            { code: 62120, name: 'Airtel', alias: 'airtel_ng' },
            { code: 62130, name: 'MTN', alias: 'mtn_ng' },
            { code: 62150, name: 'Glo', alias: 'glo_ng' },
            { code: 62160, name: 'Etisalat', alias: 'etisalat_ng' },
        ],
    },
    {
        phone: 250,
        mcc: 635,
        code: 'RW',
        currencyCode: 'RWF',
        name: 'Rwanda',
        providers: [
            { code: 63510, name: 'MTN' },
            { code: 63513, name: 'Tigo' },
            { code: 63514, name: 'Airtel' },
        ],
    },
    {
        phone: 255,
        mcc: 640,
        code: 'TZ',
        currencyCode: 'TZS',
        name: 'Tanzania',
        providers: [
            { code: 64002, name: 'Tigo', alias: 'tigo_tz' },
            { code: 64003, name: 'Zantel', alias: 'zantel_tz' },
            { code: 64004, name: 'Vodacom', alias: 'vodacom_tz' },
            { code: 64005, name: 'Airtel', alias: 'airtel_tz' },
            { code: 64007, name: 'TTCL', alias: 'ttcl_tz' },
            { code: 64009, name: 'Halotel', alias: 'halotel_tz' },
        ],
    },
    {
        phone: 251,
        mcc: 636,
        code: 'ET',
        currencyCode: 'ETB',
        name: 'Ethiopia',
        providers: [
            { code: 63601, name: 'Ethiotel' },
        ],
    },
    {
        phone: 260,
        mcc: 645,
        code: 'ZM',
        currencyCode: 'ZMW',
        name: 'Zambia',
        providers: [
            { code: 64501, name: 'Airtel' },
            { code: 64502, name: 'MTN' },
            { code: 64503, name: 'Zamtel' },
        ],
    },
    {
        phone: 27,
        mcc: 655,
        code: 'ZA',
        currencyCode: 'ZAR',
        name: 'South Africa',
        providers: [
            { code: 65501, name: 'Vodacom', alias: 'vodacom_za' },
            { code: 65502, name: 'Telkom', alias: 'telkom_za' },
            { code: 65507, name: 'Cell C', alias: 'cellc_za' },
            { code: 65510, name: 'MTN', alias: 'mtn_za' },
        ],
    },
    {
        phone: 229,
        mcc: 616,
        code: 'BJ',
        currencyCode: 'XOF',
        name: 'Benin',
        providers: [
            { code: 61602, name: 'Mov' },
            { code: 61603, name: 'MTN' },
            { code: 61604, name: 'BBCom' },
            { code: 61605, name: 'Glo' },
        ],
    },
    {
        phone: 233,
        mcc: 620,
        code: 'GH',
        currencyCode: 'GHS',
        name: 'Ghana',
        providers: [
            { code: 62001, name: 'MTN', alias: 'mtn_gh' },
            { code: 62002, name: 'Vodafone', alias: 'vodafone_gh' },
            { code: 62006, name: 'Airtel Tigo', alias: 'airtel_gh' },
        ],
    },
    {
        phone: 243,
        mcc: 630,
        code: 'CD',
        currencyCode: 'CDF',
        name: 'Democratic Republic of Congo',
        providers: [
            { code: 63001, name: 'Vodacom' },
        ],
    },
    {
        phone: 1,
        mcc: 310,
        code: 'US',
        currencyCode: 'USD',
        name: 'United States of America',
        providers: [],
    },
    {
        phone: 49,
        mcc: 262,
        code: 'DE',
        currencyCode: 'EUR',
        name: 'Germany',
        providers: [],
    },
    {
        phone: 257,
        mcc: 642,
        code: 'BI',
        currencyCode: 'BIF',
        name: 'Burundi',
        providers: [
            { code: 64207, name: 'Smart' },
            { code: 64282, name: 'Econet' },
            { code: 64208, name: 'Lumitel' },
        ],
    },
    {
        phone: 232,
        mcc: 619,
        code: 'SL',
        currencyCode: 'USD',
        name: 'Sierra Leone',
        providers: [
            { code: 61902, name: 'Millicom' },
            { code: 61940, name: 'Datatel' },
            { code: 61901, name: 'Celtel' },
            { code: 61905, name: 'Africell' },
            { code: 61904, name: 'Comium' },
        ],
    },
    {
        phone: 228,
        mcc: 615,
        code: 'TG',
        currencyCode: 'XOF',
        name: 'Togo',
        providers: [
            { code: 61503, name: 'Moov' },
            { code: 61501, name: 'Togocel' },
            { code: 61599, name: 'Togo Telecom' },
        ],
    },
    {
        phone: 223,
        mcc: 610,
        code: 'ML',
        currencyCode: 'XOF',
        name: 'Mali',
        providers: [
            { code: 61001, name: 'Malitel' },
            { code: 61002, name: 'Orange' },
        ],
    },
    {
        phone: 225,
        mcc: 612,
        code: 'CI',
        currencyCode: 'XOF',
        name: 'Côte d\'Ivoire (Ivory Coast)',
        providers: [
            { code: 61202, name: 'Moov' },
            { code: 61203, name: 'Orange' },
            { code: 61205, name: 'MTN' },
        ],
    },
    {
        phone: 221,
        mcc: 608,
        code: 'SN',
        currencyCode: 'XOF',
        name: 'Senegal',
        providers: [
            { code: 60801, name: 'Orange' },
            { code: 60802, name: 'Free' },
            { code: 60803, name: 'Expresso' },
        ],
    },
    {
        phone: 224,
        mcc: 611,
        code: 'GN',
        currencyCode: 'GNF',
        name: 'Guinea(Conakry)',
        providers: [
            { code: 61101, name: 'Orange' },
            { code: 61102, name: 'Sotelgui' },
            { code: 61103, name: 'Intercel' },
            { code: 61104, name: 'MTN Areeba' },
            { code: 61105, name: 'Cellcom' },
        ],
    },
    {
        phone: 245,
        mcc: 632,
        code: 'GNB',
        currencyCode: 'XOF',
        name: 'Guinea-Bissau',
        providers: [
            { code: 63201, name: 'GUINETEL' },
            { code: 63202, name: 'MTN' },
            { code: 63203, name: 'Orange' },
            { code: 63207, name: 'Guinetel' },
        ],
    },
    {
        phone: 237,
        mcc: 624,
        code: 'CM',
        currencyCode: 'CFA',
        name: 'Cameroon',
        providers: [
            { code: 62401, name: 'MTN' },
            { code: 62402, name: 'Orange' },
            { code: 62404, name: 'Nextel' },
        ],
    },
    {
        phone: 20,
        mcc: 602,
        code: 'EG',
        currencyCode: 'EGP',
        name: 'Egypt',
        providers: [
            { code: 60201, name: 'Orange' },
            { code: 60202, name: 'Vodafone' },
            { code: 60203, name: 'Etisalat' },
            { code: 60204, name: 'WE' },
        ],
    },
];
export default countries;
