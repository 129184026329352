import PerfectScrollbar from 'perfect-scrollbar';

const mountSideNav = () => {
    const sideNavOpen = document.querySelector('.js-header__sidenav-open');
    const sideNavClose = document.querySelector('.js-header__sidenav-close');
    const sideNav = document.querySelector('.js-side-nav');
    const sideNavContainer = document.querySelector('.js-side-nav__container');
    const sideNavContent = document.querySelector('.side-nav__content');

    const appContent = document.querySelector('.js-app__content');

    let supportsPassive;
    let customScrollbar = false;

    // Used while swiping the sidenav
    let startX = 0;
    let currentX = 0;
    let touchingSideNav = false;

    let Ps;

    const onTransitionEnd = () => {
        sideNav.classList.remove('side-nav--animatable');
        sideNav.removeEventListener('transitionend', onTransitionEnd);
    };

    const hideSideNav = () => {
        sideNav.classList.add('side-nav--animatable');
        sideNav.classList.remove('side-nav--visible');
        appContent.classList.remove('app__content--side-nav--visible');
        sideNav.addEventListener('transitionend', onTransitionEnd);
    };

    const showSideNav = () => {
        sideNav.classList.add('side-nav--animatable');
        sideNav.classList.add('side-nav--visible');
        appContent.classList.add('app__content--side-nav--visible');
        sideNav.addEventListener('transitionend', onTransitionEnd);
    };

    window.hideSideNav = hideSideNav;
    window.showSideNav = showSideNav;

    const closeAllNestedMenus = (currentClicked) => {
        if (currentClicked.classList.contains('side-nav__menu-item--open')) return;

        const currentNested = sideNavContent.querySelectorAll('.side-nav__menu-item.more.side-nav__menu-item--open');
        // Begin with inner most nested menu
        const nestedKeys = Object.keys(currentNested).reverse();

        const isLevel2 = currentClicked.nextElementSibling.classList.contains('level-2');
        const isLevel3 = currentClicked.nextElementSibling.classList.contains('level-3');

        nestedKeys.forEach((k) => {
            const el = currentNested[k];

            if (isLevel3) {
                // Close only level-3 menus
                if (el.nextElementSibling.classList.contains('level-3')) {
                    el.classList.remove('side-nav__menu-item--open');
                }
            } else if (isLevel2) {
                // Close only level-2 menus
                if (el.nextElementSibling.classList.contains('level-2')) {
                    el.classList.remove('side-nav__menu-item--open');
                }
            } else {
                el.classList.remove('side-nav__menu-item--open');
            }
        });
    };

    const blockPropagation = (evt) => {
        evt.stopPropagation();

        if (evt.target.classList.contains('more')) {
            evt.preventDefault();

            // Close all nested menus
            closeAllNestedMenus(evt.target);

            // Open or Close nested menu
            evt.target.classList.toggle('side-nav__menu-item--open');
        }
        if (customScrollbar) Ps.update(sideNav);
    };

    const update = () => {
        if (!touchingSideNav) return;

        requestAnimationFrame(update);

        const translateX = Math.min(0, currentX - startX);
        sideNavContainer.style.transform = `translateX(${translateX}px)`;
    };

    const onTouchStart = (evt) => {
        if (!sideNav.classList.contains('side-nav--visible')) return;

        startX = evt.touches[0].pageX;
        currentX = startX;

        touchingSideNav = true;
        requestAnimationFrame(update);
    };

    const onTouchMove = (evt) => {
        if (!touchingSideNav) return;

        currentX = evt.touches[0].pageX;
    };

    const onTouchEnd = () => {
        if (!touchingSideNav) return;

        touchingSideNav = false;

        const translateX = Math.min(0, currentX - startX);
        sideNavContainer.style.transform = '';

        if (translateX < 0) {
            hideSideNav();
        }
    };

    // apply passive event listening if it's supported
    const applyPassive = () => {
        if (supportsPassive !== undefined) {
            return supportsPassive ? { passive: true } : false;
        }

        // feature detect
        let isSupported = false;
        try {
            document.addEventListener('test', null, { get passive() {
                isSupported = true;
            } });
        } catch (e) { } // eslint-disable-line no-empty

        supportsPassive = isSupported;
        return applyPassive();
    };

    const loadScrollBar = () => {
        Ps = new PerfectScrollbar(sideNav, {
            minScrollbarLength: 20,
            suppressScrollX: true,
        });

        customScrollbar = true;
    };
    const addEventListeners = () => {
        sideNavOpen.addEventListener('click', showSideNav);
        if (sideNavClose) sideNavClose.addEventListener('click', hideSideNav);
        sideNav.addEventListener('click', hideSideNav);
        sideNavContainer.addEventListener('click', blockPropagation);

        document.addEventListener('touchstart', onTouchStart, applyPassive());
        document.addEventListener('touchmove', onTouchMove, applyPassive());
        document.addEventListener('touchend', onTouchEnd);
    };

    if (sideNav) {
        addEventListeners();

        if (window.matchMedia('(max-width: 39.9375em)').matches) {
            // hide on small device
            // FIXME: find a better way
            hideSideNav();
        } else {
            loadScrollBar();
        }
    }
};

export default mountSideNav;
