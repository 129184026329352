<template>
    <aside class="js-side-nav side-nav side-nav--account side-nav--visible">
        <nav class="js-side-nav__container side-nav__container">
            <header class="side-nav__header">
                 <div class="side-nav__header-logo-wrapper clearfix show-for-medium">
                    <i class="js-header__sidenav-close ti-menu side-nav__header-icon"></i>
                    <a href="/" class="side-nav__header-logo-link"><img class="side-nav__header-logo" src="/assets/img/logos/logo-white.png" /></a>
                </div>
                <div class="show-for-small-only">
                    <div class="side-nav__header-title">
                        <img class="side-nav__header-title-logo" src="/assets/img/logos/at-logo-icon.png" />
                        <span class="side-nav__header-title-text">Africa's Talking</span>
                    </div>
                    <div class="side-nav__header-content">
                        <span class="side-nav__user"><i class="ti-user"></i>{{user.firstName}} {{user.lastName}}</span>
                    </div>
                </div>
            </header>
            
            <ul class="side-nav__content">

                <li>
                    <a href="/" v-on:click.prevent id="nav-home" class="side-nav__menu-item no-more">
                        <router-link  :to="{ name: 'teams' }" tag="div">
                            <i class="ti-home"></i>Home
                        </router-link>
                    </a>
                </li>

                <li>
                    <a href="/" v-on:click.prevent id="nav-user-settings" class="side-nav__menu-item no-more">
                        <router-link  :to="{ name: 'account' }" tag="div">
                            <i class="ti-user"></i>Account
                        </router-link>
                    </a>
                    
                </li>
                
                <li>
                    <a href="/contact" v-on:click.prevent id="nav-contact" class="side-nav__menu-item no-more">
                        <router-link  :to="{ name: 'contact' }" tag="div">
                            <i class="ti-comments-smiley"></i>Contact Us
                        </router-link>
                    </a>
                </li>
            </ul>
        </nav>
    </aside>
</template>

<script>
    import { getUser, isAliasing } from '../../../utils/api/auth';

    export default {

        data: () => ({
            isAliasing: isAliasing(),
            user: getUser(),
        }),

    };
</script>
